$spacer-sizes: 0, 4, 8, 12, 16, 20, 24, 32;

// Class names are doubled up to increase specificity to 2
@each $size in $spacer-sizes {
    .spacer--#{$size}.spacer--#{$size} {
        margin: #{$size}px; }
    .spacer__top--#{$size}.spacer__top--#{$size} {
        margin-top: #{$size}px; }
    .spacer__bottom--#{$size}.spacer__bottom--#{$size} {
        margin-bottom: #{$size}px; }
    .spacer__left--#{$size}.spacer__left--#{$size} {
        margin-left: #{$size}px; }
    .spacer__right--#{$size} {
        margin-right: #{$size}px; }
    .spacer__pad--#{$size}.spacer__pad--#{$size} {
        padding: #{$size}px; }
    .spacer__padTop--#{$size}.spacer__padTop--#{$size} {
        padding-top: #{$size}px; }
    .spacer__padBottom--#{$size}.spacer__padBottom--#{$size} {
        padding-bottom: #{$size}px; }
    .spacer__padLeft--#{$size}.spacer__padLeft--#{$size} {
        padding-left: #{$size}px; }
    .spacer__padRight--#{$size}.spacer__padRight--#{$size} {
        padding-right: #{$size}px; } }
