@import "../../../../styles/mixins/breakpoints";
@import "../../../../styles/base/colors";
@import "../../../../styles/base/helpers";
@import "../../../../styles/layout/grid";

.asset-container {
    padding: 0 1em 1em 1em;
    @include respond-to-smaller(small-screens) {
        @include grid(6);
        padding-left: 6px;
        padding-right: 6px; }

    @include respond-to(medium-screens) {
        @include grid(4); }

    @include respond-to(wide-screens) {
        @include grid(3); }

    &.compact {
        width: 20em;
        @include respond-to(handhelds) {
            width: 100%; } }
    &.learn:not(.compact) {
        @include respond-to(handhelds) {
            @include grid(12); }

        @include respond-to(small-screens) {
            @include grid(6); }

        @include respond-to(medium-screens) {
            @include grid(4); }

        @include respond-to-larger(medium-screens) {
            @include grid(3); }

        .asset {
            .image-box:before {
                padding-top: 52%; }
            .info {
                background-color: inherit;
                h3, .h3 {
                    font-size: 1.2em;
                    height: 1.8em; }
                p {
                    font-size: 0.9em;
                    height: 5em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 1.3em; }
                a {
                    &:hover, &:focus, &:active {
                        color: $accent-color; } } } } }
    .asset {
        &--owned .image-box {
            opacity: 0.5; }
        &--owned {
            .categories {
                flex-grow: 0!important;
                flex-shrink: 1!important;
                flex-basis: calc(100% - 88px)!important; } }
        .asset-status {
            padding: 0.2em 0.4em;
            display: inline-block;
            text-transform: uppercase;
            font-weight: 800;
            font-size: 16px;
            position: absolute;
            bottom: 100%;
            right: 0;
            pointer-events: none;
            &--owned {
                background: #000;
                color: $accent-text; }
            &--new {
                background: $yellow;
                color: $link; }
            &--featured {
                background: $accent-color;
                color: $accent-text; } }
        &.odd {
            .rating-board__pop {
                @media screen and ( max-width: 400px ) {
                    margin-left: calc(80px - 17em) !important; }
                @media (min-width: 401px) and ( max-width: $break-small ) {
                    margin-left: calc(80px - 13em) !important; } } }

        width: 100%;
        border-radius: 2px;
        font-family: $font-family;
        overflow: visible;
        background-color: $card-bg;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
        single-transition: box-shadow, 0.1s, ease-in-out, 0s;
        height: auto;

        &:hover, &:focus, &:active {
            background-color: $card-bg-hover;
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3); }

        .image-box {
            position: relative;
            width: 100%;
            overflow: visible;
            display: table;
            border-radius: 2px 2px 0 0;
            &:before {
                content: "";
                display: block;
                padding-top: 100%; }
            > div {
                position: absolute;
                overflow: hidden;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                > div {
                    display: table;
                    width: 100%;
                    height: 100%;
                    > div {
                        background-size: contain;
                        display: table-cell;
                        text-align: center;
                        vertical-align: middle;
                        img {
                            width: 100%;
                            height: auto; } } } } }
        .asset-discount-note {
            display: inline-block;
            text-align: left;
            color: $highlight-text;
            background-color: $discount-color;
            padding: 0 0.4em;
            .asset-discount-percentage {
                line-height: 1.5em;
                font-weight: bold; } }
        .asset-discount-price {
            white-space: nowrap;
            color: darken($accent-text, 40%);
            text-decoration: line-through;
            margin-right: 0.2em;
            font-size: 0.85em; }
        .info {
            padding: 0.5em 0.5em 0;
            background-color: inherit;
            position: relative;
            h3, .h3 {
                line-height: 1.5em;
                font-size: 16px;
                font-family: $font-family;
                font-weight: 400;
                margin: 0;
                -webkit-font-smoothing: subpixel-antialiased;
                @include respond-to(handhelds) {
                    font-size: 0.8em; }
                .ellipsis-text {
                    text-overflow: ellipsis;
                    display: block;
                    overflow-x: hidden;
                    white-space: nowrap; }
                a {
                    color: $asset-link-color;
                    font-family: $font-family;
                    &:hover {
                        color: $link; } } }
            .asset-download-link {
                margin: 0.5em auto 0 auto;
                width: 100%; }
            .buylink-spacer {
                height: 56px; }
            .price-container {
                text-align: right;
                margin: 4px 0;
                .asset-price {
                    background-color: transparent !important;
                    -webkit-font-smoothing: subpixel-antialiased; } }
            .price-discount-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 4px 0;
                @media screen and (max-width: $break-small) {
                    display: block;
                    text-align: left; }
                .asset-discount-price-wrapper {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex-wrap: wrap; } }
            .creator {
                height: 1.5em;
                line-height: 1.5em;
                a {
                    color: $accent-color;
                    font-weight: 400;
                    text-decoration: none;
                    font-size: 14px;
                    &:hover {
                        color: darken($accent-color, 20%); } } }
            ul.details {
                border-top: 1px solid $header-border;
                position: relative;
                padding: 5px 7px;
                width: calc(100% + 1em);
                margin-left: -.5em;
                background-color: inherit;
                display: flex;
                @include respond-to-smaller(small-screens) {
                    padding: 5px 2px; }
                li {
                    background-color: inherit;
                    &.categories {
                        position: relative;
                        background-color: transparent;
                        flex: 0 1 calc(100% - 100px);
                        @media screen and (max-width: 576px) {
                            flex: 0 1 calc(100% - 60px); }
                        overflow: hidden;
                        ul.mock-text-ellipsis {
                            width: 100%;
                            li.mock-ellipsis-item-helper {
                                display: flex;
                                margin-bottom: 2px;
                                a.mock-ellipsis-item-cat {
                                    width: 100%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis; } } } }
                    & > * {
                        background-color: inherit;
                        font-size: 14px;
                        font-family: $font-family;
                        font-weight: 300; }
                    a {
                        font-weight: 400;
                        font-size: 12px;
                        color: $accent-color;
                        &:hover {
                            color: darken($accent-color, 20%); }
                        @include respond-to-smaller(small-screens) {
                           font-size: 11px; } } }
                .add-btn {
                    position: absolute;
                    display: inline;
                    right: 6px;
                    &.shift-left {
                        right: 36px;
                        @media screen and (max-width: 576px) {
                            right: 0; } }
                    top: 6px;
                    padding: 0 0.5em;
                    font-size: 12px;
                    line-height: 24px;
                    height: 24px;
                    border-radius: 0!important;
                    -webkit-border-radius: 0!important;
                    -moz-border-radius: 0!important;
                    @media screen and (max-width: 576px) {
                        padding: 0 0.3em;
                        font-size: 0.7em; }
                    @media screen and (max-width: 350px) {
                        padding: 0 0.2em;
                        font-size: 0.6em; }
                    &.add-review-btn {
                        background: $yellow;
                        color: $black;
                        &:hover {
                            background: $light-yellow;
                            color: $black; } } } }
            .ellipsis {
                a {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block; } }

            .rating-board {
                width: 100%;
                &__pop {
                    margin-left: calc(80px - 9em); } } } } }

.vault-page, .assets-block, .related-assets {
    .asset-container {
        @include respond-to(xlarge-screens) {
            @include grid(2.4); } } }
.vault-page .asset--owned .image-box {
    opacity: 1; }
