@import '../../../../styles/base/helpers';
@import '../../../../styles/mixins/breakpoints';

.asset-list-group {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    float: left;
    @include respond-to-smaller(small-screens) {
        width: 100%; } }

.asset-list-group-loading {
    align-items: flex-start;
    justify-content: center; }

.vault-page, .assets-block, .seller-assets, .related-assets {
    .asset-list-group {
        width: 100%; } }
