.review-summary-modal {
    text-align: left;
    &__header {
        line-height: 2em;
        border-bottom: 1px solid $header-border;
        padding-bottom: 0.5em;
        font-weight: bold;
        font-size: 1.2em; }
    &__content {
        padding: 1em;
        max-height: 80vh;
        overflow-y: auto;
        &.self_warning {
            font-weight: 600; }
        > .loading {
            background: url("../../../../images/loading-bars.svg") center no-repeat;
            width: 100%;
            height: 50px; } }

    &__target .review-item__images li {
        pointer-events: none; }

    &__action__text {
        margin: 20px 0; }

    &__footer {
        padding: 0 1em;
        .error {
            line-height: 1.5em; } } }
