@import '../../../../../styles/mixins/breakpoints';

.tabList {
    display: flex;
    border-bottom: 1px solid $header-border;
    margin-bottom: 16px;
    justify-content: flex-start;
    flex-wrap: wrap;
    .report-product {
        align-self: center;
        margin: 0 0 0 auto;
        .fa-flag {
            padding-right: 8px; }
        @media screen and (max-width: $break-large) {
            min-width: 11.2em;
            padding: 12px 1em;
            text-align: center; }
        @media screen and (max-width: $break-small) {
            margin: unset; }
        @media screen and (max-width: 420px) {
            text-align: left; } } }
