@import '../../../../styles/base/_colors';
@import '../../../../styles/base/_helpers';
@import '../../../../styles/mixins/breakpoints';

.asset-details-container {
    overflow-y: visible!important;
    max-width: $break-largest;
    margin-bottom: 1em;
    .fab-disclaimer {
        background-color: $tile-color;
        padding: 10px 16px;
        margin-bottom: 40px;
        display: flex;
        gap: 12px;
        .fa-exclamation-circle {
            color: #FFC229;
            padding: 4px 0; }
        .copy {
            display: flex;
            flex-direction: column;
            gap: 4px;
            a {
                width: fit-content; } }
        a {
            color: #26BBFF; } }
    .asset-details {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        min-height: 500px;

        &__gallery {
            width: 57%;
            @media (max-width: 1023px) {
                width: 100%; } }
        &__content {
            width: 41%;
            position: relative;
            margin-left: 2%;
            @media not all and (min-resolution: .001dpcm) {
                margin-left: 1%; }
            @media (max-width: 1023px) {
                width: 100%;
                margin-left: 0; } } }
    .asset-acquire-callout {
        background-color: $light-bg;
        height: auto;
        width: 100%;
        margin: 20px 0;
        @media (min-width: 1024px) {
            position: absolute;
            bottom: 0;
            right: 0;
            margin-bottom: 0; }
        .btn-group-wrap {
            text-align: center; }

        .asset-acquire__container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between; }

        .asset-acquire-right, .asset-acquire-left {
            padding: 1em;
            width: 50%; }
        .asset-acquire-left {
            padding-left: 0.2em;
            padding-right: 0.2em;
            min-height: 100%;
            .loading {
                background: url("../../../../images/loading-bars.svg") center no-repeat;
                width: 100%;
                height: 50px; } }
        .asset-acquire-right {
            min-height: 100%;
            @include respond-to-smaller(handhelds) {
                font-size: 11px; }
            .row {
                margin-bottom: 10px;
                width: 100%;
                .platforms-text {
                    white-space: normal; }
                .fa {
                    margin-right: 6px; }
                .label {
                    display: inline;
                    padding: .2em .6em .3em;
                    margin-bottom: 5px;
                    text-align: left;
                    margin-left: 7px;
                    font-size: 13px;
                    color: $header-color;
                    font-weight: bold;
                    line-height: 1;
                    vertical-align: baseline;
                    border-radius: .25em; }

                .social-links-wrapper {
                    margin-left: 15px; } } }

        .distribution-method-text, .promotional-content-text {
            cursor: default;
            border-bottom: 1px dashed $line-color; }

        .distribution-method-tooltip, .promotional-content-tooltip {
            max-width: 300px;
            background-color: black;
            opacity: 0.85;
            @include respond-to-smaller(medium-screens) {
                margin-left: 140px; } }

        .purchase {
            text-align: center;
            width: 100%;
            position: relative;
            display: flex;
            flex-flow: column;
            @include clearfix;
            .discount-div {
                top: 1em;
                margin: 0 auto;
                text-align: center;
                width: 100%;
                font-weight: bold;
                position: absolute;
                > span {
                    position: relative; }
                .base-price {
                    text-decoration: line-through;
                    color: darken(white, 40%); } }

            .asset-get-btn {
                margin-top: 4em !important;
                &:not(.btn-group) {
                    padding: 0 1em;
                    @include respond-to(handhelds) {
                        padding: 0; } }
                .btn {
                    padding: 0 1em; }
                &.btn-group {
                    vertical-align: unset;
                    .btn {
                        border-radius: 0;
                        max-width: unset;
                        min-width: unset;
                        margin: 0 auto;
                        width: auto;
                        height: auto; } }
                .dropdown-menu {
                    li {
                        &:hover {
                            background-color: $header-color;
                            color: $header-highlight; } } } }

            .btn {
                max-width: 10em;
                min-width: 8em;
                width: 100%;
                margin-bottom: 1em;
                @include respond-to(handhelds) {
                    max-width: 100%; }
                &.ja {
                    max-width: 13.5em; } }
            .btn[data-toggle="dropdown"] {
                min-width: 2em; }

            .or {
                font-size: 0.75em;
                margin: 0.8em auto -0.75em;
                letter-spacing: 1px;
                text-transform: uppercase; }
            .save-discount {
                font-size: 2em;
                &.discounted {
                    margin-bottom: 1em; }
                @media screen and (max-width: $break-small) {
                    font-size: 1.5em;
                    line-height: 2em; } }
            .discount-div.shopping-cart-enabled {
                top: 3em;
                font-size: 0.9em;
                font-weight: normal; }
            a.btn:not(.asset-get-btn) {
                margin-top: 1em !important;
                &:nth-last-child(1):first-child {
                    margin-top: 4em !important; } }
            a.btn.disabled-btn {
                cursor: not-allowed;
                opacity: .65;
                box-shadow: none;
                background-color: #444; }
            a.btn.adding {
                cursor: default;
                background-color: $color-link-hover;
                &:hover {
                    background-color: $color-link-hover; } } } }
    .asset-detail-full {
        width: 100% !important;
        @media screen and (max-width: $break-small) {
            padding: 0 10px; }
        > .pull-left {
            margin-bottom: 10px;
            @media (min-width: 1200px) {
                margin-top: 0; } }
        h1 {
            font-size: 2em;
            font-weight: normal;
            text-transform: none;
            display: inline;
            line-height: inherit;
            @media (min-width: 1024px) and (max-width: 1100px) {
                font-size: 1em;
                margin-top: 0; }
            @media (max-width: 1200px) {
                font-size: 1.5em;
                margin-top: 0; } }
        [class^="icon-"], [class*="icon-"] {
            margin-right: 0.5em; }

        .asset-detail-text {
            width: 100%;
            // overflow: hidden
            @media (max-width: 1200px) {
                font-size: 12px; } }

        .asset-desc-nvs {
            @media (max-width: 1200px) {
                font-size: 12px; }
            .verified-trader-icon {
                background: url("../../../../images/trader-accent.svg");
                background-size: cover!important;
                height: 15px;
                width: 15px;
                position: relative;
                display: inline-block;
                top: 2px;
                margin: 0 2px 0 4px;
                &:hover {
                    background: url("../../../../images/trader-accent-hover.svg")!important;
                    background-size: cover!important;
                    cursor: pointer; } } }

        .platforms-container {
            margin-top: 1em; }
        .comment-window {
            display: none; }
        .compatibility {
            .fa {
                color: $header-color;
                &.incompatible {
                    color: $danger; }
                &.partial {
                    color: $info; }
                &.compatible {
                    color: $success; } } }
        .share-links {
            display: inline-block;
            @include respond-to(handhelds) {
                display: block; } }
        .asset-description {
            max-width: 40em;
            margin: 1em 0; }
        .asset-desc-price {
            color: $asset-price-color;
            font-size: 1.2em;
            margin-left: 0.5em; }
        .asset-desc-list-price {
            color: $asset-list-price-color;
            font-size: 1em;
            margin-left: 0.5em;
            text-decoration: line-through; }
        .asset-desc-save-price {
            color: $asset-price-color;
            font-size: 1em;
            margin-left: 0.5em; } }

    .asset-progress-container {
        .progress {
            margin: 1.5em;
            border-radius: 0;
            .progress-bar {
                border-radius: 0; } }
        .progress-text-container {
            margin-bottom: 1em; } }

    #asset-acquire-progress-container {
        height: 200px;
        .btn-group {
            padding-top: 20px;
            .btn,
            .btn:last-child {
                border-radius: .4em!important;
                margin-right: 8px;
                padding: 5px 7px; }
            .btn:last-child {
                margin-right: 0; } }
        .progress-text-container {
            text-align: left;
            padding-left: 25px; } }
    #asset-download-progress {
        padding-top: 60px; }
    #asset-purchase-loading {
        width: 100%;
        text-align: center;
        margin-top: 4em; }

    .asset-comment-container {
        padding-top: 20px;
        &__footer {
            text-align: right;
            a {
                cursor: pointer; } } }

    .asset-reviews__btn--new {
        color: black;
        background: $yellow;
        &:hover {
            color: black;
            background: $light-yellow; } }

    .asset-content-container {
        margin-top: 2em;
        .beta {
            bottom: 8px; } }

    &.learn {
        margin-top: 0;
        padding: 2em 0; } }
