@import '../../../../../styles/mixins/_breakpoints';

$success: #95c990;
$warning: #262626;
$info: #fff8db;
$danger: #dc5353;
$text: #fff;
$white: #fff;

.alert-container {
    position: relative;
    margin-bottom: 20px;
    @include respond-to-smaller(small-screens) {
        margin: 0; }
    .alert-info-icon {
        width: 23px;
        height: 23px; }
    .alert {
        margin-bottom: 0;
        padding: 7.5px; }
    .icon-col {
        padding: 10px; }

    .enterprise-icon-close {
        color: $white; }

    .message-col {
        padding: 10px;
        width: 83%;
        p {
            padding: 0;
            @include respond-to-smaller(small-screens) {
                font-size: 14px; }
            @include respond-to-smaller(xlarge-screens) {
                font-size: 10px; } }
        .resend-link {
            border: none;
            background: transparent;
            color: #2196f3; } }
    .icon-container {
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        left: 2%;
        .alert-info-icon {
            height: 23px;
            width: 23px;
            font-size: 1.4em;
            background: transparent;
            vertical-align: text-bottom; } }
    .alert-success {
        border-color: $success;
        background-color: $success; }
    .alert-warning {
        border-color: $warning;
        background-color: $warning; }
    .alert-info {
        border-color: $info;
        background-color: $info; }
    .alert-danger {
        border-color: $danger;
        background-color: $danger; }

    .alert-success,
    .alert-warning,
    .alert-info,
    .alert-danger {
        border-radius: 4px;
        color: $text; }
    .alert-info {
        border-radius: 2px;
        margin-bottom: 10px;
        background-color: $info;
        border: 1px solid #eac874;
        box-shadow: 0 1px 3px rgba(0,0,0,0.1);
        color: #a1a1a1;
        p {
            color: #a1a1a1; } }
    .close {
        right: 2%;
        opacity: 1;
        background: transparent;
        border: none;
        line-height: 1.4em;
        font-size: 1.2em;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include respond-to-smaller(handhelds) {
            font-size: 1em; }
        .epic-blog-icon-close {
            color: $text; } }

    p {
        font-size: 1em;
        color: $text;
        padding: 10px;
        margin-bottom: 0; } }

.alert-container.verification-container {
    .alert-success,
    .alert-info,
    .alert-danger {
        border-radius: 2px;
        margin-bottom: 10px;
        box-shadow: 0px 1px 3px rgba(0,0,0,0.1); }
    .message-col {
        margin-left: 8.3%; } }
