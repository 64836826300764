.error-page {
    width: 100%;
    position: relative;
    margin-top: 0!important;
    padding-bottom: 1em!important;
    .error-box {
        width: 520px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        top: 28%;
        padding: 50px 55px 50px 35px; } }
