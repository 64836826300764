@import "../../../../../styles/base/colors";
.validate-text-area {
    margin: 20px 0;
    $self: &;
    textarea {
        resize: none;
        background-color: #171717;
        color: #fff;
        width: 100%;
        height: 10em;
        padding: .5em;
        border: 1px solid #3b3c3d;
        outline: none;
        &#{$self}--error {
            border: 1px solid $error; } }
    .error {
        color: $error;
        line-height: 1.5em; } }
