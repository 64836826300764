@import "../../../../styles/base/colors";
@import "../../../../styles/mixins/breakpoints";

.trader-info-modal {
    display: flex;
    flex-direction: row;
    padding: 0;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 165%;
    letter-spacing: 0.28px;
    color: #E6E6EA;
    @media screen and (max-width: $break-medium) {
        flex-direction: column-reverse;
        width: 100%; }
    .left-col {
        flex-basis: 33.33%;
        padding: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        background: #111;
        @media screen and (max-width: $break-medium) {
            padding: 16px;
            gap: 12px; }
        .icons {
            display: flex;
            flex-direction: row;
            gap: 12px;
            svg {
                height: 48px;
                width: 48px;
                @media screen and (max-width: $break-medium) {
                    height: 24px;
                    width: 24px; } }
            .verified-trader-icon {
                background: url("../../../../images/trader-grey.svg");
                background-size: cover;
                height: 48px;
                width: 48px;
                @media screen and (max-width: $break-medium) {
                    height: 24px;
                    width: 24px; }
                position: relative;
                display: inline-block; } }
        .verified-trader-title {
            font-size: 20px;
            font-weight: 700;
            line-height: 125%;
            letter-spacing: 0.4px; }
        .verified-trader-description {
            color: #AAAAAE; }
        .verified-trader-learn-more {
            color: #AAAAAE;
            a {
                color: $accent-color;
                &:hover, &:focus, &:active {
                    text-decoration: none;
                    color: lighten($accent-color, 20%); } } } }
    .right-col {
        flex-basis: 66.66%;
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 20px;
        background: $nav-color;
        @media screen and (max-width: $break-medium) {
            padding: 16px;
            gap: 12px; }
        .loading-wrap {
            position: absolute;
            left: 0;
            right: 0;
            .lottie-wrap {
                height: 200px;
                width: 200px;
                @media screen and (max-width: $break-medium) {
                    height: 140px;
                    width: 140px; } } }
        .verified-trader-seller {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            .verified-trader-seller-name {
                font-size: 32px;
                font-weight: 700;
                line-height: 125%;
                @media screen and (max-width: $break-medium) {
                    font-size: 24px; } }
            .verified-trader-icon {
                background: url("../../../../images/trader-grey.svg");
                background-size: cover;
                height: 32px;
                width: 32px;
                position: relative;
                display: inline-block; } }
        .verified-trader-details {
            position: relative;
            overflow: hidden;
            height: 100%;
            .trader-info-list {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                height: 100%;
                width: 100%;
                @media screen and (max-width: $break-medium) {
                    position: relative;
                    &.placeholder {
                        height: 156px; } }
                .trader-info-item {
                    display: flex;
                    flex-direction: column;
                    .info-title {
                        color: #AAAAAE;
                        line-height: 150%;
                        letter-spacing: 0.2px;
                        &.placeholder {
                            background-color: #AAAAAE;
                            opacity: 0.1;
                            height: 14px;
                            margin: 3.5px 0 3.5px 0; } }
                    .info-content {
                        color: #E6E6EA;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 165%;
                        &.placeholder {
                            background-color: #AAAAAE;
                            opacity: 0.1;
                            height: 16px;
                            margin: 5.2px 0 5.2px 0; } } } }
            .gradient {
                background: linear-gradient(0deg, $nav-color, rgba(0, 0, 0, 0) 100%);
                position: absolute;
                bottom: 0px;
                height: 25%;
                width: 100%; }
            .btn-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                position: absolute;
                left: 0;
                right: 0;
                .btn {
                    z-index: 1;
                    padding: 10px 20px;
                    text-transform: none;
                    background-color: $accent-color !important;
                    color: $link-color !important;
                    &:hover, &:focus, &:active {
                        background-color: lighten($accent-color, 20%) !important {
                                color: lighten($link-color, 20%) !important; } } }
                .error {
                    width: 75%;
                    @media screen and (max-width: $break-medium) {
                        width: 100%; }
                    padding-top: 8px;
                    text-align: center;
                    color: $red; } } } } }
