@import "../../../../../styles/base/colors";

.rich-text-content {
  p {
    margin: 0;
    padding: 0; }

  strong {
    font-weight: bold; }

  em {
    font-style: italic; }

  u {
    text-decoration: underline; }

  s {
    text-decoration: line-through; }

  blockquote {
    border-left: 4px solid $border-gray !important;
    padding-left: 16px;
    margin: 5px 0;
    color: #949494; }

  ol ol, ul ol {
    list-style-type: lower-roman; }

  ul, ol {
    padding: 0;
    padding-left: 1.5em;
    margin: 0; }

  ol {
    list-style-type: decimal !important;
    color: $accent-text;
    li {
      color: $accent-text; } }

  ul {
    list-style-type: none !important;
    color: $accent-text;
    li::before {
      content: '\2022';
      font-size: 100%;
      display: inline-block;
      white-space: nowrap;
      width: 1.2em;
      margin-left: -1.5em;
      margin-right: 0.3em;
      text-align: right!important; } }

  a {
    color: $primary-color;
    margin: 0 2px;
    padding: 0;
    vertical-align: baseline;
    text-decoration: none;
    transition: color 300ms;

    &:hover {
      text-decoration: none;
      color: $primary-color-hover; } } }
