@import '../../../../../styles/base/colors';
@import '../../../../../styles/mixins/breakpoints';

// =============== RC-PAGINATION ===============

.rc-pagination {
    float: right;
    display: inline-block;
    @include respond-to-smaller(small-screens) {
        margin: 0 8px!important;
        float: left; }
    > li a {
        position: relative;
        float: left;
        padding: 3px 8px;
        margin-left: -1px;
        height: 26px;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid #d1d1d1;
        color: #0aaff1;
        text-transform: uppercase;
        font-size: .9em;
        cursor: pointer;
        &:hover {
            background-color: #eee; } }
    &-item {
        height: 26px;
        outline: none!important;
        display: inline-block;
        a {
            color: $accent-color;
            text-transform: uppercase;
            font-size: .9em; }
        &-active {
            cursor: default;
            user-select: none;
            &:focus {
                outline: none; }
            a {
                background-color: #f5f5f5!important;
                border-color: #0aaff1!important;
                color: #212529!important;
                cursor: default!important;
                user-select: text!important;
                z-index: 1!important;
                font-size: .85em!important;
                font-weight: bold!important; } } }
    &-item-link {
        padding: 0 12px!important;
        display: inline-block; }
    &-total-text {
        display: inline-block;
        padding: 0 10px 0 2px;
        position: relative;
        top: -8px;
        @include respond-to-smaller(small-screens) {
            display: block; } }
    &-jump-prev, &-jump-next {
        height: 26px;
        outline: none!important;
        display: inline-block;
        &:after {
            content: "...";
            display: block;
            color: #ccc;
            font-size: 16px;
            position: absolute;
            padding: 0 5px 1px 6px;
            border: 1px solid $header-border;
            margin-left: -1px;
            background-color: $white;
            cursor: pointer;
            min-width: 26px;
            text-align: center;
            height: 26px; } }
    &-jump-prev {
        &:hover {
            &:after {
                content: "«";
                padding: 0 5px 0 7px;
                letter-spacing: 2px;
                font-size: .98em;
                background-color: #f5f5f5;
                color: $accent-hover; } } }
    &-jump-next {
        &:hover {
            &:after {
                content: "»";
                padding: 0 5px 0 7px;
                letter-spacing: 2px;
                font-size: .98em;
                background-color: #f5f5f5;
                color: $accent-hover; } } }
    &-prev, &-next {
        height: 26px;
        outline: none!important;
        display: inline-block;
        a {
            font-size: 16px!important; } }
    &-prev {
        a {
            border-left-width: 1px;
            -webkit-border-radius: 3px 0 0 3px;
            -moz-border-radius: 3px 0 0 3px;
            border-radius: 3px 0 0 3px;
            &:after {
                content: "‹";
                display: block;
                margin-top: -1px; } } }
    &-next {
        a {
            -webkit-border-radius: 0 3px 3px 0;
            -moz-border-radius: 0 3px 3px 0;
            border-radius: 0 3px 3px 0;
            &:after {
                content: "›";
                display: block;
                margin-top: -1px; } } }
    &-disabled {
        a {
            display: none;
            cursor: default;
            background-color: #f1f1f1;
            color: $text-gray; } }
    &-options {
        float: right;
        margin-left: 14px;
        display: inline-block;
        &-size-changer {
            float: left; }
        &-quick-jumper {
            float: left;
            margin-left: 10px;
            input {
                margin: 0 0 0 2px;
                box-sizing: border-box;
                background-color: $white;
                border: 1px solid $header-border;
                outline: none;
                padding: 0 4px;
                width: 40px;
                height: 26px; } } } }


// =============== RC-SELECT ===============

.rc-select {
    display: inline-block;
    width: 152px;
    position: relative;

    .rc-select-selector {
        display: flex;
        position: relative; }

    .rc-select-selection-search {
        width: 100%;

        &-input {
            width: 100%;
            opacity: 1!important;
            cursor: pointer;
            border: 1px solid $header-border;
            padding: 0 8px;
            line-height: 1.5em;
            &:focus {
                outline: none; } } }

    .rc-select-selection-item {
        position: absolute;
        top: 1px;
        left: 8px;
        pointer-events: none; }

    &-show-arrow {

        .rc-select-arrow {
            pointer-events: none;
            position: absolute;
            right: 8px;
            top: -7px;
            font-size: 1.4em;
            color: $text-gray;

            &-icon::after {
                content: "";
                border: 7px solid transparent;
                width: 0;
                height: 0;
                display: inline-block;
                border-top-color: #999;
                transform: translateY(7px); } } }

    &-focused {
        .rc-select-selector {
            border-color: blue !important; } }

    &-dropdown {
        position: absolute;
        top: 25px!important;
        background: #fff;
        width: 100%;
        left: 0;
        list-style: none;
        padding-left: 0;
        background-color: $white;
        border: 1px solid $header-border;
        text-align: left;
        z-index: 10;

        &-hidden {
            display: none; } }

    &-item {
        cursor: pointer;
        padding: 0 8px;

        &-option {
            position: relative;

            &-state {
                position: absolute;
                right: 6px;
                top: 0;
                pointer-events: none;
                display: none; }

            &-active {
                background-color: $accent-color;
                color: $accent-text; }

            &-disabled {
                color: $formGrayText; } } } }
