@import '../../../../styles/base/_colors';
@import '../../../../styles/mixins/_breakpoints';
.enableShoppingCart {
    .shopping-cart {
        display: inline-block;
        position: relative;
        width: 2em;
        margin-left: 1em;
        z-index: 3;
        outline: none;
        .icon {
            position: relative;
            &:hover {
                transform: scale(1.2);
                transition: all .3s ease-out; }
            .cart-amount {
                position: absolute;
                top: 11px;
                right: -15px;
                background-color: $discount-color;
                border-radius: 8px;
                color: $accent-text;
                height: 16px;
                min-width: 16px;
                padding: 1px 2px 0;
                text-align: center;
                font-weight: bolder;
                font-size: 11px; } }

        .shopping-cart-icon {
            font-size: 24px; }

        .max-amount-alert, .dropdown-content {
            position: absolute;
            top: 57px;
            right: 0;
            max-width: 24em;
            width: calc(100vw - 2em);
            color: $accent-text;
            background-color: $shopping-cart-bg;
            -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
            padding: 0.8em;
            z-index: 2;
            transition: all .3s ease-in;
            -webkit-transition: all .3s ease-in;
            -moz-transition: all .3s ease-in;
            -ms-transition: all .3s ease-in;
            -o-transition: all .3s ease-in;
            &:before {
                position: absolute;
                content: '';
                display: block;
                border-bottom: 10px solid $shopping-cart-bg;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                top: -10px;
                right: 15px; } }

        .max-amount-alert {
            text-align: center;
            z-index: 3;
            .fa-warning {
                color: $danger; } }
        .sign-in-wrapper {
            text-align: center;
            display: block;
            z-index: 1;
            .fa-warning {
                color: $danger; }
            #sign-in-cart {
                width: 50%;
                margin: 1em auto; } }

        .dropdown-content {
            height: 7em;
            max-height: 36em;
            &.has-data {
                height: 36em; }
            .cart-content-wrapper {
                transition: visibility 0s ease-in .3s;
                -webkit-transition: visibility 0s ease-in .3s;
                -moz-transition: visibility 0s ease-in .3s;
                -ms-transition: visibility 0s ease-in .3s;
                -o-transition: visibility 0s ease-in .3s;
                &.invisible {
                    transition: visibility 0s ease-out 0s;
                    -webkit-transition: visibility 0s ease-out 0s;
                    -moz-transition: visibility 0s ease-out 0s;
                    -ms-transition: visibility 0s ease-out 0s;
                    -o-transition: visibility 0s ease-out 0s; } } }

        @media only screen and (max-device-height: $break-medium-landscape) {
            .dropdown-content {
                height: calc(100vh - 8em)!important; } }
        .no-offers {
            font-size: 1.2em;
            text-align: center;
            padding-top: 1.3em;
            .fa-warning {
                color: $warning; } }
        .cart-header {
            padding: .5em 0;
            border-bottom: 1px solid grey; }
        .footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0 0.7em 0.7em 0.7em;
            font-size: .9em;
            background-color: inherit;
            >div:first-child {
                border-top: 1px solid grey;
                padding-top: .5em; }
            .total {
                text-transform: uppercase; }
            div>span {
                float: right; }
            .footer-btn-group {
                margin-top: .5em;
                a.btn.disabled-btn {
                    cursor: not-allowed;
                    opacity: .65;
                    box-shadow: none;
                    background-color: #444; }
                .btn {
                    float: left;
                    width: calc(50% - 3px);
                    text-transform: uppercase;
                    -webkit-border-radius: 0!important;
                    -moz-border-radius: 0!important;
                    border-radius: 0!important; }
                .cart-close {
                    background-color: transparent!important;
                    color: $accent-color;
                    border: 1px solid $accent-color;
                    &:hover {
                        color: lighten($accent-color, 10%)!important; } }
                .checkout {
                    margin-left: 6px !important;
                    max-width: none; } } }
        .cart-list {
            overflow-y: scroll;
            overflow-x: hidden; }
        .cart-item {
            position: relative;
            margin: .5em 0;
            background-color: #2d2d2d;
            padding: .5em;
            &:after {
                display: table;
                content: '';
                clear: both; }

            button.wishlist-toggle {
                background-color: transparent;
                color: white;
                opacity: 0.2; } }

        .image-wrapper {
            width: 35%;
            height: 64px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            float: left;
            img {
                max-width: 100%;
                max-height: 100%;
                display: block;
                margin: 0 auto; } }
        .info-wrapper {
            padding-left: .5em;
            width: 60%;
            float: left;
            p {
                font-size: .8em;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow-x: hidden; } }
        .origin-price {
            opacity: 0.5;
            text-decoration: line-through;
            padding-right: 4px; }
        .remove {
            float: right;
            cursor: pointer;
            position: relative;
            top: 1em;
            line-height: 1em;
            font-size: 1em;
            &:hover {
                color: darken($nav-color, 10%); } } }

    img.cart-loading {
        width: 4em;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

    .cart-offer-list {
        max-height: 27em;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 0.3em 0 0;
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background: $shopping-cart-scroll-bg; }

        &::-webkit-scrollbar-track {
            background: $shopping-cart-scroll-bg;
            border-radius: 50px; }

        &::-webkit-scrollbar-thumb {
            background: $shopping-cart-scroll-thumb;
            border-radius: 50px; }

        &::-webkit-scrollbar-track:hover {
            background: $shopping-cart-scroll-bg-highlight; }

        &::-webkit-scrollbar-thumb:horizontal:hover, ::-webkit-scrollbar-thumb:vertical:hover {
            background: $shopping-cart-scroll-thumb-highlight; }

        &::-webkit-scrollbar-button {
            width: 8px;
            height: 8px; }

        &::-webkit-scrollbar-corner {
            background-color: transparent; } }
    @media only screen and (max-device-height: $break-medium-landscape) {
        .cart-offer-list {
            height: calc(100vh - 17em)!important; } } }
