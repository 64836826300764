@import '../../../../styles/base/colors';
@import '../../../../styles/mixins/breakpoints';

.filter-section {
    float: right;
    width: 20%;
    overflow: visible;
    font-size: 1em;
    display: inline-block;
    position: relative;
    background-color: $nav-color;
    right: 0;
    -webkit-transition: {}
    -moz-transition: {}
    -ms-transition: {}
    -o-transition: {}
    transition: background-color 0.3s;
    @include respond-to-smaller(small-screens) {
        overflow: hidden;
        width: 0;
        height: 0;
        background-color: rgba(red($nav-color), green($nav-color), blue($nav-color), 0);

        .asset-tags p.title {
            display: none; } }

    article {
        padding: .5em;
        -webkit-transition: {}
        -moz-transition: {}
        -ms-transition: {}
        -o-transition: {}
        transition: transform 0.3s ease-in;
        @include respond-to-smaller(small-screens) {
            width: 50%;
            min-width: 250px;
            float: right;
            background-color: $nav-color;
            -webkit-transform: {}
            -moz-transform: {}
            -ms-transform: {}
            -o-transform: {}
            transform: translateX(100%); } }

    &.visible-small-screen {
        @include respond-to-smaller(small-screens) {
            display: block;
            position: fixed;
            overflow: visible;
            z-index: 1002;
            width: 100%;
            height: 100vh;
            top: 53px;
            background-color: rgba(red($nav-color), green($nav-color), blue($nav-color), 0.7); }
        article {
            @include respond-to-smaller(small-screens) {
                -webkit-transform: {}
                -moz-transform: {}
                -ms-transform: {}
                -o-transform: {}
                transform: translateX(0); } } }

    .filter-header {
        >span {
            font-weight: bold; }
        a {
            float: right;
            cursor: pointer;
            margin-left: 10px; } }
    .filter-dropdown {
        width: 100%;
        ul {
            margin-top: 0;
            font-size: 1em;
            display: inline; }
        .title {
            font-size: 1.2em;
            @include respond-to-smaller(small-screens) {
                display: none; } }

        li, .show-all-versions {
            display: inline-block;
            background-color: darken($nav-color, 10%);
            padding: 0 1em;
            border-radius: 8px/50%;
            margin: .5em .5em 0 0;
            cursor: pointer;
            font-size: .8em;
            &:hover, &:active, &:focus {
                background-color: darken($nav-color, 15%);
                color: $accent-text; }
            &.selected {
                background-color: $accent-color;
                color: $accent-text;
                display: inline-block!important; } }
        .show-all-versions {
            display: none;
            font-size: .8em; }
        .filter-block {
            margin-bottom: 1em; }

        .price-range {
            .custom-range {
                height: auto;
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                white-space: pre;
                font-size: .8em;
                span {
                    &:first-child {
                        display: inline-block;
                        background-color: #d6d6d6;
                        padding: 0 1em;
                        border-radius: 8px/50%;
                        margin: .5em .5em 0 0;
                        cursor: pointer; }
                    &.selected {
                        background-color: $accent-color;
                        color: $accent-text;
                        display: inline-block!important; } }
                input {
                    border: 1px solid darken($nav-color, 10%);
                    padding-right: 0.2em;
                    width: 25%;
                    min-width: 1.5em;
                    text-align: right;
                    margin-left: 2px;
                    background-color: #171717;
                    &:focus {
                        outline: none; }
                    &.error {
                        border: 1px solid $danger;
                        color: $danger; } }
                .warning {
                    color: $danger;
                    font-weight: bold; } } }
        .btn-group {
            width: 100%; } }

    .filter-icon {
        padding: 0 1em; }
    .show-more {
        font-size: 0.9em;
        cursor: pointer; }
    .open {
        .current-sort, .filter-icon {
            background-color: $nav-color;
            color: $header-color; } }

    .asset-tags {
        .title {
            display: inline-block; }
        .selected-tags {
            display: block;
            li::after {
                content: "X";
                margin-left: 0.5em;
                font-size: 8px;
                vertical-align: middle; } }

        .search-panel {
            margin: 10px 0 0 0;
            width: 100%;
            span {
                position: absolute; }
            input {
                width: 100%;
                line-height: 1em;
                padding-right: 3em; }

            ul.autocomplete li {
                border-radius: 0;
                margin: 0;
                &.selected {
                    display: block !important; } } } } }
