@import "../../../../styles/base/colors";
@import "../../../../styles/mixins/breakpoints";

@mixin shoppingCartTransition {
    -webkit-transition: all .125s ease-in;
    -moz-transition: all .125s ease-in;
    -ms-transition: all .125s ease-in;
    -o-transition: all .125s ease-in;
    transition: all .125s ease-in; }


.store-page-asset {
    .store-featured-items-container {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 10px;
        a:not(.btn) {
            color: $darker-text;
            width: 32%;
            padding-bottom: 20px;
            &:hover, &:active, &:focus {
                color: darken($darker-text, 20%); }
            @include respond-to(small-screens) {
                width: 49%; }
            @include respond-to(handhelds) {
                width: 100%; } }
        h2, span {
            color: $asset-active-description-color;
            font-size: 1.1em;
            @media (max-width: 1024px) {
                font-size: 1em; } }

        .asset-discount-price {
            text-decoration: line-through;
            color: $asset-list-price-color; }

        .featured-tile {
            width: 100%;
            position: relative;
            height: 15em;

            .featured-text {
                background: $accent-color;
                color: $accent-text;
                padding: 0 10px;
                display: inline-block;
                text-transform: uppercase;
                font-weight: 800;
                font-size: 12px;
                position: absolute;
                bottom: 4em;
                left: 15px; }

            .featured-image {
                position: absolute;
                top: 0;
                left: 0; }

            .featured-info {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.65)));
                background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
                background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
                background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
                min-height: 200px;
                padding: 15px;

                .name,
                .price {
                    line-height: 0.8em;
                    @media (max-width: 1024px) {
                        line-height: 1em; } }

                .price {
                    display: flex;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                    font-weight: bold;
                    @media (max-width: 1024px) {
                        position: absolute;
                        right: 0;
                        bottom: 33px !important; } }

                .name {
                    @media (min-width: 1024px) {
                        max-width: calc(100% - 5em); }
                    &.discounted {
                        @media (min-width: 1024px) {
                            max-width: calc(100% - 10em); } } }

                .name-price-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    width: calc(100% - 30px);
                    position: absolute;
                    bottom: 15px; } } } }

    &.enableShoppingCart:not(.not-logged-in) {
        .store-featured-items-container {
            .featured-info {
                @include shoppingCartTransition;
                &:not(.owned):not(.external-asset) {
                    padding: 0; }
                &.owned, &.external-asset {
                    .add-cart-row, .price:not(.owned) {
                        display: none; }
                    .price.owned {
                        display: inline!important; } } }
            .info-wrapper {
                width: 100%;
                height: 10em;
                padding: 15px;
                position: absolute;
                bottom: 0;
                left: 0;
                @include shoppingCartTransition; }
            .add-cart-row {
                height: 0;
                padding: 0 15px;
                overflow: hidden;
                position: absolute;
                width: 100%;
                bottom: 0;
                @include shoppingCartTransition; }
            .add-cart-btn {
                display: inline-block;
                float: right;
                width: auto;
                padding: 0 1em;
                border-radius: 0!important;
                -webkit-border-radius: 0!important;
                -moz-border-radius: 0!important;
                border-radius: 0!important; }

            .featured-tile:hover {
                &:not(.owned):not(.external-asset) {
                    .add-cart-row {
                        height: 3em; }
                    .info-wrapper {
                        bottom: 3em; }
                    &.featured-info {
                        height: 13em; } } } } } }

