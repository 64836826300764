@mixin hover-icon {
    display: block;
    position: absolute;
    width: 100%;
    top: calc(50% - 0.7em);
    font-family: 'icomoon';
    color: rgba(255,255,255,0.5);
    text-align: center; }

.review-images {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px !important;

    li {
        width: 150px;
        height: 84px;
        margin: 5px;
        position: relative;
        cursor: pointer;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover; }
        &:hover {
            &:before {
                content: "\e907";
                font-size: 2em;
                @include hover-icon; } } }

    &__full {
        .image-gallery-image {
            position: relative;
            text-align: center;
            &:hover {
                &:before {
                    content: "\e908";
                    font-size: 50px;
                    @include hover-icon; } } }
        &.responsive .image-gallery-image img {
            max-width: 177vh; } } }
