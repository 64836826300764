@import '../../../../../styles/base/_colors';

.iconBtn {
    width: 1.5em;
    height: 1.5em; }

.iconBtn--primary {
    background: $accent-color;
    color: $accent-text;
    border-radius: 2px;
    &:hover {
        background: darken($accent-color, 20%); } }

.iconBtn--inverse {
    background: none;
    color: $accent-color;
    &:hover {
        color: darken($accent-color, 20%); } }
