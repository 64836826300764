@import '../../../../../styles/mixins/breakpoints';
.sort-select {
    margin-bottom: 10px;
    padding: 0 10px;
    display: inline-block;
    width: 25%;
    @include respond-to-smaller(xlarge-screens) {
        width: 100%; }
    &.asset-list-sort.disabled {
        visibility: hidden; }
    .sort-title {
        display: inline-block;
        margin-right: 4px;
        line-height: 1.5em;
        strong {
            font-weight: bold; } } }
