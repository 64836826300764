@import '../../../../../styles/base/_colors';

.select-div {
    position: relative;
    display: inline-block;
    border: 1px solid $header-border;
    padding: 0 8px;
    line-height: 1.5em;
    cursor: pointer;
    &.disabled {
        cursor: default;
        background-color: #f1f1f1;
        color: $text-gray; }

    .fa-sort-down {
        position: absolute;
        top: -4px;
        right: 0;
        font-size: 1.4em;
        color: $text-gray; }

    .current-value {
        position: relative;
        text-align: left; }

    .select-options {
        position: absolute;
        width: 101.3%;
        left: -1px;
        list-style: none;
        padding-left: 0;
        background-color: $white;
        border: 1px solid $header-border;
        text-align: left;
        z-index: 10;
        li {
            padding: 2px 10px;
            cursor: pointer;
            line-height: 1.5em;
            &:hover, &:active, &:focus, &.selected {
                background-color: $accent-color;
                color: $accent-text; }
            i {
                float: right;
                font-size: 20px;
                position: relative;
                top: 6px; } } } }
