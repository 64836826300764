@import '../../../../styles/base/_colors';
@import '../../../../styles/base/_helpers';
@import '../../../../styles/mixins/breakpoints';

.image-gallery {
    .image-gallery-showcase {
        button, a {
            &:focus, &:hover {
                border: none;
                outline: none;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none; } }
        .image-gallery-content.fullscreen {
            .image-gallery-slide-wrapper {
                .image-gallery-left-nav, .image-gallery-right-nav {
                    visibility: visible; }
                .image-gallery-image:hover {
                    &:before {
                        content: "\e908"; } } } }
        &.responsive .image-gallery-content.fullscreen .image-gallery-slide-wrapper .image-gallery-image img {
            max-width: 177vh; }

        .image-gallery-slide-wrapper {
            .image-gallery-left-nav, .image-gallery-right-nav {
                visibility: hidden; }
            .image-gallery-image {
                width: 100%;
                height: auto;
                display: block;
                background: none;
                z-index: 4;
                text-align: center;
                &:before {
                    display: none; }
                &:hover {
                    cursor: pointer;
                    &:before {
                        font-family: 'icomoon';
                        content: "\e907";
                        display: block;
                        font-size: 59px;
                        color: rgba(255,255,255,0.5);
                        position: absolute;
                        top: 50%;
                        width: 100%;
                        height: 1em;
                        text-align: center;
                        @include respond-to(handhelds) {
                            display: none; } } }

                img {
                    width: 100%;
                    height: 100%;
                    min-height: 24em;
                    object-fit: cover;
                    &.no-image {
                        width: 100%;
                        height: 24em;
                        margin: 0 auto;
                        object-fit: fill; }
                    &.loading-spinner {
                        height: 4em;
                        width: 4em;
                        object-fit: fill; }
                    @include respond-to(handhelds) {
                        min-height: inherit !important; } } } } }
    .image-gallery-thumbnails {
        position: relative;
        padding: 0;
        margin-top: 10px;
        .alice-carousel {
            max-width: calc(100% - 100px);
            a.image-gallery-thumbnail {
                width: 150px;
                height: 74px;
                border: none;
                img {
                    opacity: .4; }
                &.active, &:focus, &:hover {
                    cursor: pointer;
                    border: none;
                    img {
                        opacity: 1; } } } }
        .thumbnails-controls {
            a {
                position: absolute;
                height: 74px;
                width: 30px;
                bottom: 0;
                text-align: center;
                background: #4c4c4e;
                cursor: pointer;
                &.disabled {
                    cursor: not-allowed;
                    opacity: .65; }
                &.control-prev {
                    left: 0; }
                &.control-next {
                    right: 0; }
                i {
                    margin-top: 55%;
                    &:before {
                        display: block;
                        margin-top: {}
                        color: #fff;
                        font-size: 40px;
                        line-height: 1; } } } } } }
