@import '../../../../styles/base/_colors';
@import '../../../../styles/mixins/_breakpoints';

.seller-assets {
    margin-top: 10px!important;
    .sort-select.asset-list-sort.disabled {
        width: 100%; }
    .profile-assets-container {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        float: left;
        @media screen and (max-width: $break-medium) {
            width: 100%;
            padding: 0 10px; }
        &.discontinued {
            width: 100%;
            float: none; }
        .left-column {
            width: 80%;
            @media screen and (max-width: $break-medium) {
                width: 70%; }
            @media screen and (max-width: $break-small) {
                width: 100%; }
            .sub-header {
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                a:not(.btn) {
                    color: $accent-color;
                    &:hover, &:focus, &:active {
                        color: darken($accent-color, 20%); } }
                .verified-trader-icon {
                    background: url("../../../../images/trader-accent.svg");
                    background-size: cover;
                    height: 32px;
                    width: 32px;
                    position: relative;
                    display: inline-block;
                    top: 6px;
                    margin: 0 8px;
                    &:hover {
                        background: url("../../../../images/trader-accent-hover.svg");
                        background-size: cover;
                        cursor: pointer; } } }
            .asset-desc-nvs {
                margin-top: 1em;
                & > span:first-child {
                    display: inline-block;
                    width: 7.5em;
                    line-height: 1em;
                    text-align: left;
                    vertical-align: baseline;
                    padding: 0; }
                & > span:last-child {
                    margin: 0;
                    padding: 0;
                    text-align: left; }
                .trader-details-btn:hover {
                    cursor: pointer; }
                .non-trader {
                    color: $global-nav-link; } } }
        .right-column {
            width: 20%;
            &:not(.has-seller-details) {
                padding-bottom: 4px; }
            &.has-trader-info {
                padding-bottom: 0px; }
            @media screen and (max-width: $break-medium) {
                width: 30%;
                padding-top: 16px; }
            @media screen and (max-width: $break-small) {
                width: 100%; }
            .report-seller {
                float: right;
                text-align: right;
                @media screen and (max-width: $break-small) {
                    float: left;
                    text-align: left; }
                .fa-flag {
                    padding-right: 8px; } } }
        hr {
            width: 100%; } } }
