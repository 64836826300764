@import "../../../../styles/base/colors";

.rating-board {
    position: relative;
    display: inline-block;
    $self: &;
    &__bar {
        cursor: default;
        .fa-angle-down {
            margin: 0 5px; } }

    &__average {
        height: auto;
        vertical-align: middle; }

    &__pop {
        display: none;
        position: absolute;
        background: transparent;
        margin-left: calc(90px - 8.75em);
        margin-top: -0.5em;
        padding: 10px 0;
        z-index: 3;
        font-size: 12px;

        &__container {
            background: white;
            position: relative;
            padding: 10px;
            border: 1px solid $border-gray; }

        &__corner {
            position: absolute;
            width: 1em;
            height: 1em;
            background: inherit;
            border-color: inherit;
            border-style: solid;
            border-width: 1px 0 0 1px;
            transform: rotate(45deg);
            left: calc(50% - 0.5em);
            top: -0.5em; }

        &__title {
            width: 100%;
            text-align: center;
            font-size: 14px;
            span {
                color: $text-gray;
                margin-left: 5px; } }

        &__content {
            width: 19.5em;
            li {
                margin: 4px 10px;
                >div, >span {
                    vertical-align: middle; }
                .rate {
                    height: 13px;
                    svg {
                        height: 13px;
                        width: 13px; } } }
            &__percent {
                display: inline-block;
                width: 100px;
                height: 12px;
                margin: 0 6px;
                background: $light-bg;
                >div {
                    height: 100%;
                    background: $accent-color; } } }

        &__btn {
            text-align: center;
            font-size: 14px;
            margin-top: 5px;
            a {
                cursor: pointer; } } }

    &:hover {
        #{$self}__pop {
            display: block; } } }
