@import "../../../../styles/base/colors";

body.touch .link-dropdown {
    &:hover {
        & > ul {
            display: none !important; } }

    ul {
        display: none; }

    &.touch-visible {
        background-color: $nav-color !important; }

    &.touch-visible ul {
        display: block !important;
        background-color: $nav-color !important;
        color: $header-color;
        outline: none; } }
