@import "../../../../../styles/base/colors";
.validate-select {
    margin: 20px 0;
    .select-div {
        width: 100% !important; }
    &--error {
        border: 1px solid $error; }
    .error {
        color: $error;
        line-height: 1.5em; } }
