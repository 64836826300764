@import '../../../../../styles/base/_colors';

.enterprise-modal_subscribe-error {
    font-family: $brutal;
    padding: 2em 0 0;
    max-width: 700px;

    .error-icon {
        text-align: center;
        color: $red;
        font-size: 4em; }

    .error-title {
        font-size: 1.8em;
        margin-bottom: 1em;
        color: $red;
        font-family: $brutal-light; }

    .gray-box {
        background-color: #f7f7f7;
        padding: 2em 3em 2em;
        p {
            text-align: left; } }

    .placeholder {
        margin: 3em; }

    .btn {
        margin: 2em 0;
        padding: 0.6em 6em;
        font-size: 0.85em; } }


