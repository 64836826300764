$text-decorations: underline;
$font-styles: italic, normal;
$font-weights: bold, normal;
$text-aligns: left, right, center;
$font-sizes: 14, 16, 18, 20, 24;

@each $val in $text-decorations {
    .text__dec--#{$val} {
        text-decoration: #{$val}; } }

@each $val in $font-styles {
    .font__style--#{$val} {
        font-style: #{$val}; } }

@each $val in $font-weights {
    .font__weight--#{$val} {
        font-weight: #{$val}; } }

@each $val in $text-aligns {
    .text__align--#{$val} {
        text-align: #{$val}; } }

@each $val in $font-sizes {
    .font__size--#{$val} {
        font-size: #{$val}px; } }
