@import "../base/colors";

@mixin heading($type: h1, $style: default) {

	@if $type == 'h1' {
		font-size: 1.8em; }
	@else if $type == 'h2' {
		font-size: 1.5em; }

	@if $style == default {
		font-family: $font-family;
		font-weight: bold;
		text-transform: uppercase;
		color: $default-heading; } }

@mixin headings($type: 'full', $bgc: #000, $c1: #fff, $c2: $accent-color) {
	width: 100%;
	height: 160px;
	background-color: $bgc;
	display: block;
	position: relative;

	& > * {
		max-width: 1200px;
		margin: 0 auto; }

	h1 {
		color: $c1;
		font-size: 40px;
		text-transform: uppercase;
		line-height: 40px;
		padding: 0;
		padding-top: 80px; }

	h2 {
		color: $c2;
		font-size: 26px;
		line-height: 26px;
		padding: 0; } }
