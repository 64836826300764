.full-screen-modal {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    padding: 0;
    background: transparent;
    &__overlay {
        z-index: 99999;
        padding: 0; }

    &__close {
        position: absolute;
        right: 20px;
        bottom: 120px;
        z-index: 2;
        color: white;
        cursor: pointer;
        &:before {
            font-family: 'icomoon';
            font-size: 30px;
            content: '\e98a'; } }

    &__content {
        position: relative;
        .image-gallery-content {
            height: 100vh; }
        .image-gallery-slide {
            background: transparent; }
        .image-gallery-image img {
            max-width: calc(177vh - 177px);
            max-height: calc(100vh - 100px); }
        .image-gallery-thumbnails-wrapper {
            position: absolute;
            width: 100%;
            height: 100px;
            bottom: 0;
            background: rgba(0, 0, 0, 0.75);
            .image-gallery-thumbnails {
                margin-top: 5px; }
            .image-gallery-thumbnail {
                width: auto;
                img {
                    width: 150px;
                    height: 84px; } } }
        .image-gallery-slide-wrapper {
            height: calc(100vh - 100px);
            button, a {
                &:focus, &:hover {
                    border: none;
                    outline: none;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none; } } } }
    .dialog__close {
        display: none; } }
