@import "../../../../../styles/mixins/breakpoints";

.asset-detail-rating {
    margin: 10px 0;
    $self: &;
    &__board {
        display: inline-block;
        @media only screen and (min-width: 1024px) and (max-width: 1130px) {
            width: 100%;
            &+#{$self}__summary {
                margin-left: -10px; } }
        @media only screen and (min-width: $break-small) and (max-width: 1023px) {
            .rating-board__pop {
                margin-left: 0; } }
        @media only screen and (max-width: $break-small - 1) {
            width: 100%;
            .rating-board__pop {
                margin-left: 0; }
            &+#{$self}__summary {
                margin-left: -10px;
                font-size: 0.8em; } } }
    &__summary {
        display: inline-block;
        margin-left: 10px;
        &:first-child {
            margin-left: 0;
            a {
                padding: 0; } }
        a {
            padding: 0 10px;
            cursor: pointer; }
        &__sep {
            display: inline-block;
            height: 0.9em;
            width: 1px;
            background: black;
            vertical-align: middle; } }

    .loading {
        background: url("../../../../../images/loading-bars.svg") left no-repeat;
        height: 1.5em; } }
