@import "../../../../../styles/base/colors";
@import "../../../../../styles/base/helpers";
@import "../../../../../styles/mixins/breakpoints";

.summary-input {
    $self: &;
    line-height: 2em;

    &__fields {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap; }

    &__rating-container {
        width: 120px;
        @include respond-to-smaller(handhelds) {
            width: 100%;
            margin-bottom: 10px;
            #{$self}__error-message {
                margin-left: 10px; } }
        &+#{$self}__input-container {
            width: calc(100% - 120px);
            @include respond-to-smaller(handhelds) {
                width: 100%; } }
        svg {
            vertical-align: sub; } }

    &__input-container {
        width: 100%; }

    &__input {
        width: 100%;
        padding: 0 1em;
        border-radius: 5px;
        border: 1px solid $border-gray;
        outline: none;
 }        //box-shadow: inset 0 1px 2px 0 $border-gray

    &__message {
        display: block;
        text-align: left; }

    // error state
    .summary-input__input-container--error, .summary-input__rating-container--error {
        #{$self}__input {
            border-color: $error; }
        #{$self}__rating {
            border: 1px solid $error;
            border-radius: 5px; }
        #{$self}__error-message {
            color: $error; } } }


