@import "../../../../styles/base/colors";
.image-list {
    &.disabled {
        li img {
            pointer-events: none; }
        .file-upload {
            border-color: $disable-text;
            color: $disable-text;
            pointer-events: none; } }
    &.error .file-upload {
        border-color: $error;
        color: $error; }
    ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
            margin: 5px;
            width: 150px;
            height: 84px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                cursor: pointer; }

            >div {
                height: 100%;
                width: 100%; }

            .file-upload {
                height: 100%;
                width: 100%;
                font-size: 1.2em;
                border: 3px dashed $accent-color;
                text-align: center;
                cursor: pointer;
                color: $accent-color;
                &:before {
                    display: block;
                    content: "+";
                    font-size: 2em;
                    font-weight: bold; } } } }

    .upload-status {
        text-align: center;
        height: 1.5em;
        .loading {
            background: url("../../../../images/loading-bars.svg") center no-repeat;
            background-size: contain;
            height: 100%; }
        .success {
            color: $success; }
        .error {
            color: $error; } }

    &--error {
        color: $error;
        line-height: 2em; } }
