@import '../../../../styles/mixins/_breakpoints';

.dismissibleBanner {
    display: flex;
    flex-direction: column;
    height: 80px;
    position: relative;
    &:hover {
        text-decoration: none; }
    @include respond-to-smaller(small-screens) {
        max-height: 140px !important;
        height: auto; }
    @include respond-to-smaller(handhelds) {
        max-height: 162px !important;
        height: auto; } }

// need !important to override high-specificity launcher styles
.dismissibleBanner__img {
    height: 80px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @include respond-to(small-screens) {
        display: block; }
    @include respond-to-smaller(small-screens) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 162px !important;
        height: auto; } }

// selector is doubled up to override default with specificity 2
.dismissibleBanner__title.dismissibleBanner__title {
    align-self: center;
    padding: .3em .8em;
    color: #ffffff;
    font-size: 1.15em;
    line-height: 22px;
    letter-spacing: -0.03em;
    font-weight: bold !important;
    @include respond-to(small-screens) {
        padding: 15px 26px 15px 92px; }
    @include respond-to-smaller(small-screens) {
        padding: 15px 30px;
        text-align: center; } }

.dismissibleBanner__cta {
    background: linear-gradient(96.51deg, #1C91DA 0%, #67DFFC 138.79%);
    color: #ffffff;
    padding: 0 20px;
    display: block;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    line-height: 40px;
    white-space: nowrap;
    @include respond-to-smaller(small-screens) {
        margin: 0 30px 15px;
        display: block;
        text-align: center;
        min-width: 180px; }
    @include respond-to-smaller(handhelds) {
        width: calc(100% - 60px); } }

.dismissibleBanner__closeBtn {
    position: absolute;
    right: 5px;
    top: 20px;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    padding: 0;
    @include respond-to-smaller(handhelds) {
        right: 5px;
        top: 25px; } }

.dismissibleBanner__closeIcon:hover {
    background-color: rgba(0, 0, 0, 0.5); }
