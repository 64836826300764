@import "../base/colors";

$comment-primary-color: #1e1e1e;
$comment-primary-hover: lighten($comment-primary-color, 15);
$comment-blog-text: #1e1e1e;
$comment-back: #1e1e1e;
$comment-off-black: #1e1e1e;
$comment-white: #ffffff;
$comment-grey: #1e1e1e;
$comment-transparent-grey: rgba(0,0,0,0.8);
$comment-modal-close: #1e1e1e;
$comment-date: #1e1e1e;
$comment-vote: #1e1e1e;

@mixin commentBox {
    width: 100%;
    height: 140px !important;
    max-height: 200px;
    border: 1px solid $darker-bg-border;
    padding: 20px;
    font-size: 14px;
    outline: none;
    overflow: hidden!important;
    overflow-y: auto!important;
    &:focus {
        box-shadow: 0 0 5px $darker-bg-border; } }

@mixin modalHeader {
    .cw-close, .cw-title {
        display: inline-block;
        line-height: 25px;
        text-align: center; }
    .cw-title {
        background-color: $accent-color;
        color: $comment-white;
        font-size: 12px;
        position: relative;
        text-transform: uppercase;
        top: -1px;
        width: 100%; }
    .cw-close {
        position: absolute;
        top: 0;
        right: 0;
        height: 24px;
        background-color: transparent;
        cursor: pointer;
        width: 40px; }
    > p {
        text-align: center;
        margin: 1em 0; } }

.comment-rating-box, .ReactModalPortal {
    .textile-head {
        z-index: 10;
        top: 0;
        position: relative;
        line-height: 1em;
        margin-bottom: 10px;
        li {
            background-color: transparent;
            cursor: pointer;
            display: inline-block;
            height: 28px;
            line-height: 28px;
            margin: 0 5px 0 0;
            position: relative;
            text-align: center;
            width: auto;
            top: -1px;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            padding: .2em 1.2em;
            border-radius: 0;
            border: 1px solid $accent-color;
            border-top: none;
            &.selected {
                background: $accent-color;
                border-top: none;
                color: $accent-text;
                z-index: 10; } } }
    .textile-body {
        position: relative;
        top: 0;
        height: 140px;
        left: 0;
        z-index: 1;
        margin-bottom: 0;
        .textile-edit, .textile-preview, .textile-help {
            width: 100%;
            display: none;
            &.selected {
                display: inline-block; }
            textarea {
                resize: none;
                background: transparent;
                @include commentBox; } }
        .textile-preview {
            text-align: start;
            @include commentBox; } }

    .textile-preview {
        em {
            font-style: italic; }
        strong {
            font-weight: 700; }
        ul {
            margin-left: 1.5em;
            margin-top: 0;
            margin-bottom: 11px;
            li {
                list-style: disc; } }
        ol {
            margin-top: 0;
            margin-bottom: 11px;
            -webkit-padding-start: 40px;
            li {
                list-style: decimal; } }
        a {
            color: inherit; } }

    .comment-chars-min, .comment-chars-max {
        display: none;
        font-size: 12px;
        text-align: center;
        &.comment-edit-invalid {
            color: $red;
            display: block !important; } }

    .enter-comment-button {
        color: #fff;
        overflow: hidden;
        max-width: unset;
        padding: 0 2em !important; } }
