@import '../../../../../styles/base/_colors';

.mktBtn {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    cursor: pointer;
    padding: 0;
    transition: 0.3s all ease-in;
    &.bold {
        font-weight: bold; }
    &:disabled {
        background-color: $disable-text !important;
        pointer-events: none; } }

%mktBtn-common {
    height: 45px;
    font-size: 1.125em;
    border-radius: 2px;
    padding: 0 2em; }

.mktBtn--primary {
    @extend %mktBtn-common;
    background: $accent-color;
    color: $accent-text;
    &:hover {
        background: darken($accent-color, 20%); } }

.mktBtn--alt {
    @extend %mktBtn-common;
    background: $yellow;
    color: $black;
    &:hover {
        background: darken($yellow, 20%); } }

.mktBtn--gray {
    @extend %mktBtn-common;
    background: $text-gray;
    color: $accent-text;
    &:hover {
        background: darken($text-gray, 20%); } }

.mktBtn__label {
    display: flex;
    align-content: center;
    justify-content: center; }
