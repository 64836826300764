@import '../../../../styles/mixins/_breakpoints';

.topBanner {
    display: flex;
    flex-direction: column;
    margin: 16px 30px 10px;
    height: 180px;
    position: relative;

    @include respond-to-smaller(handhelds) {
        margin: 16px 10px 0px; } }

// need !important to override high-specificity launcher styles
.topBanner__img {
    height: 180px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover; }

// selector is doubled up to override default with specificity 2
.topBanner__title.topBanner__title {
    align-self: center;
    margin: -40px 10px 0;
    padding: .3em .8em !important;
    background-color: #000000;
    color: #ffffff;
    font-size: 1.15em;
    line-height: 1;
    font-weight: bold !important;

    @include respond-to-smaller(handhelds) {
        margin: 0;
        padding: 0.4em 0.8em;
        align-self: auto !important; // override CMS style value
        width: 100%;
        text-align: center;
        bottom: 0;
        position: absolute; } }

