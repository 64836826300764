@import "../../../../styles/base/helpers";
@import "../../../../styles/base/colors";
@import "../../../../styles/mixins/breakpoints";
@import "../../../../styles/mixins/heading";

.sub-nav-container {
    position: sticky;
    background-color: $bg-site;
    top: -16px;
    z-index: 1001;
    padding: 0 2em 0;
    margin-bottom: 0;
    margin-left: calc(50% - 50vw);
    width: 100vw;
    @include clearfix;
    @include respond-to(handhelds) {
        padding: 1em 10px 0; }

    .page-header-container {
        max-width: 1340px;
        padding: 1em 0em 0px;
        margin: auto;
        border-bottom: 1px solid $header-border;
        @include clearfix;
        @include allButLargest {
            padding: 1em 0 0; }
        @include respond-to-smaller(handhelds) {
    	    padding: 0; }

        .header-block {
            @include clearfix;
            float: right;
            position: relative;
            margin-top: 8px;
            @include respond-to-smaller(handhelds) {
                width: 100%;
                float: left; }

            .asset-search {
                position: relative;
                z-index: 2;
                display: inline-block;
                margin-left: .5em;
                width: 13em;
                @include respond-to-smaller(handhelds) {
                    margin-left: 0;
                    width: calc(100% - 8em); } } }

        .page-title {
            .h1 {
                padding: 0.2em 0;
                @include heading;
                font-family: $font-family;
                font-weight: 700;
                float: left;
                a {
                    color: $link;
                    &:hover {
                        color: $link-highlight; } } }
            @include respond-to(handhelds) {
                width: 100%;
                .h1 {
                    font-size: 1.2em; } } }

        ul.header-navigation {
            @include clearfix;
            float: right;
            font-family: $font-family;
            display: block;
            position: relative;
            z-index: 1;
            @include respond-to(handhelds) {
                font-size: 0.8em; }
            @include respond-to-smaller(xlarge-screens) {
                float: left;
                width: 100%; }
            > li {
                display: inline-block;
                a.active {
                    border-bottom: 4px solid;
                    border-color: $accent-color;
                    padding-bottom: calc(1em - 4px); }
                &:hover, &:active, &:focus {
                    background-color: $nav-color;
                    color: $header-color; }
                a {
                    padding: 1em .6em;
                    @include respond-to-smaller(small-screens) {
                        padding: 1em .4em 0.75em .4em; }
                    display: inline-block;
                    color: $link;
                    + span {
                        display: inline-block;
                        width: 16px;
                        height: 16px; } }
                &:hover {
                    & > ul {
                        display: block; } }
                > ul {
                    display: none;
                    overflow-y: hidden;
                    width: unset;
                    position: absolute;
                    background-color: inherit;
                    font-size: 0.875em;
                    padding: .4em;
                    z-index: 2;
                    border: none;
                    box-shadow: none;
                    @include respond-to(handhelds) {
                        max-width: 100%; }
                    li {
                        &:last-child {
                            a {
                                border-bottom: 1px solid transparent; } }
                        a {
                            width: 100%;
                            padding: .5em .8em;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            &:hover {
                                background-color: $accent-color;
                                color: $accent-text; }
                            @include respond-to(handhelds) {
                                padding: 1em; } } } } }
            .store-top-categories ul {
                width: 15em;
                li {
                    width: 100%; } }
            .store-industries-categories ul {
                width: 16em;
                li {
                    width: 100%; } }
            .store-free-categories ul {
                width: 15em;
                li {
                    float: none;
                    width: 100%; } }
            .link-dropdown {
                i {
                    margin-left: 2px; } }
            .browse-divider {
                color: $text-gray;
                border-top: 1px solid $header-border;
                padding: .8em 1.2em; }
            .special-category {
                font-weight: bold; } }
        .mobile-nav-button {
            display: none;
            float: right;
            padding: 0.7em;
            &:hover {
                cursor: pointer; }
            @include respond-to(handhelds) {
                display: inline-block; }

            @include clearfix;
            .mobile-nav-caret {
                float: right;
                width: 16px;
                height: 16px; } } } }

.fix-header .page-header-container {
    border-bottom: none;
    max-width: 1400px;
    padding: 0 2em;
    margin: 0 auto; }

// Hack for Microsoft Edge 14+ Browser compatibility
@supports (-ms-ime-align: auto) and (not (-ms-accelerator: true)) {
    .sub-nav-container {
        margin-left: 0px;
        width: 100%; } }
