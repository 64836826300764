@import '../../../../styles/base/colors';
@import '../../../../styles/mixins/breakpoints';
@import "../../../../styles/layout/grid";

.page-body {
    min-height: 600px; }
.category-container {
    margin-top: 10px !important; }

.seller-assets .category-container {
    @include respond-to-larger(small-screens) {
        width: 80%;
        float: left; } }

.filter-link {
    display: none;
    margin: 2px 0 10px;
    padding-right: 10px;
    text-align: right;
    @include respond-to-smaller(small-screens) {
        display: block;
        float: right; } }

.error-message-container {
    margin: 1em 30px;
    color: $danger;
    font-weight: bold;
    padding: 1em;
    border: 1px solid $danger; }
