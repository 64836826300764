@import "../../../../styles/base/_colors.sass";

.asset-reviews {
    &__header {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .sort-select {
            width: 65%; } }

    &__list__empty {
        padding: 3em 0;
        text-align: center;
        button {
            margin-top: 1em; } }

    &__btn--new {
        color: black;
        background: $yellow;
        transition: 0.3s all ease-in;
        &:hover {
            background: $light-yellow; } }

    &__footer {
        text-align: right;
        .pagination {
            margin-left: 20px; } }

    &__legacy {
        cursor: pointer; }

    .loading {
        background: url("../../../../images/loading-bars.svg") center no-repeat;
        width: 100%;
        height: 50px; } }
