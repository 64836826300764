@import "../../../../styles/base/_colors.sass";
@import "../../../../styles/mixins/_breakpoints";

.review-item {
    margin: 20px 0;
    padding: 25px;
    border: 1px solid $border-gray;
    $self: &;

    &__header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        min-height: 2em; }

    &__best {
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        cursor: pointer;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background: $accent-color;
        color: $accent-text;
        &.disabled {
            pointer-events: none; }

        .fa {
            font-size: 26px !important;
            vertical-align: sub; }

        &--isBest {
            background: transparent;
            color: $success; }

        &+#{$self}__title {
            margin-left: 40px; } }


    &__title {
        line-height: 1;
        @media screen and (max-width: $break-small) {
            max-width: 100%;
            overflow-wrap: break-word; }
        .rate {
            vertical-align: middle;
            display: inline-block;
            margin-right: 10px; }
        h4 {
            @media screen and (max-width: $break-small) {
                max-width: 100%; }
            display: inline-block;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 4px;
            white-space: pre-wrap; } }
    &__sub-title {
        display: block;
        font-size: 0.9em;

        &__edit {
            color: $text-gray; }

        &__own {
            margin-left: 5px; } }

    &__helpful {
        p {
            color: $danger; }
        strong {
            font-weight: bold; }
        .fa-thumbs-up {
            padding: 2px;
            margin-left: 5px;
            background: $accent-text;
            color: $accent-color;
            cursor: pointer;
            border-radius: 3px;
            &:hover {
                color: $accent-hover; }
            &.isVoting {
                &:hover {
                    cursor: not-allowed; } }
            &.disabled {
                color: $disable-text;
                &:hover {
                    color: $disable-hover; } } } }

    &__content {
        margin-top: 10px;
        &.self_warning {
                font-weight: 600; } }
    &__tools {
        margin-top: 5px !important;
        li {
            display: inline-block;
            margin-right: 10px;
            cursor: pointer;
            vertical-align: top;
            line-height: 1.5em;
            .fa, span {
                background: transparent;
                color: $accent-color;
                &:hover {
                    color: $accent-hover; } }

            .loading {
                background: url("../../../../images/loading-bars.svg") center no-repeat;
                height: 1.5em;
                width: 30px;
                background-size: contain; }

            &.disabled {
                pointer-events: none;
                .fa-check-square {
                    color: $disable-text; } }
            &.button-show_anyway {
                color: $accent-color; } } }

    &__replies {
        margin-top: 25px;
        .review-item {
            padding-right: 0;
            margin: 0;
            border: none;
            border-top: 1px solid $border-gray; }

        &__more span {
            cursor: pointer;
            margin-left: 20px;
            color: $accent-color;
            &:hover {
                color: $accent-hover; } } }

    &__best-reply {
        padding-right: 0;
        margin: 0;
        border-width: 1px 0 0 0;
        &:first-child {
            margin-top: -25px;
            border: none; } } }
