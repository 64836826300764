@import '../../../../styles/mixins/comment';
@import '../../../../styles/base/_helpers';
@import '../../../../styles/mixins/breakpoints';

.comment-rating-box {
    width: 100%;

    .add-comment {
        margin-top: 40px; }

    textarea {
        .sort-box {
            padding-bottom: 2em; }
        .comment-item {
            .comment-body {
                text-align: right; }
            .comment-vote,
            .comment-upvote,
            .comment-downvote {
                float: right; } }
        .textile-body {
            text-align: right; }
        .sort-box a.active {
            &::before {
                left: 0; } } }

    .comment-item {
        margin-bottom: 2em;
        .comment-vote {
            display: inline-block;
            width: 40px;
            vertical-align: top;
            & > a, & > span {
                display: block;
                text-align: center;
                width: 100%; }
            .comment-upvote, .comment-downvote {
                color: $disable-text;
                pointer-events: none;
                &:hover {
                    color: darken($accent-color, 15);
                    text-decoration: none; }
                &.voted {
                    cursor: not-allowed;
                    color: darken($accent-color, 15); }
                i {
                    font-style: normal; } }
            .comment-upvote {
                i:before {
                    display: block;
                    content: '▾';
                    font-size: 1.5em;
                    transform: scale(-1); } }
            .comment-downvote {
                i:before {
                    content: '▾';
                    font-size: 1.5em; } } }
        .comment-info-box {
            display: inline-block;
            @include calc("width", "100% - 50px"); }
        .comment-body {
            margin-bottom: 1.5em;
            margin-left: 1em;
            .username {
                margin-bottom: 1em; }
            .comment-content {
                margin: 0; }
            .textile-container-view {
                .textile-preview {
                    overflow-wrap: break-word; } }
            .comment-links {
                font-size: 14px;
                margin-top: 10px;
                & > a {
                    cursor: pointer; }
                & > a, & > span {
                    color: $accent-color;
                    margin-right: 1em;
                    position: relative;
                    @include respond-to-smaller(small-screens) {
                        margin-right: 0.7em; }
                    &:hover {
                        .comment-tooltip {
                            display: block; } }
                    .comment-tooltip {
                        background: $comment-back;
                        border-radius: 4px;
                        color: $comment-white;
                        display: none;
                        font-size: 12px;
                        left: -20px;
                        line-height: 1.2;
                        margin: 0;
                        min-width: 60px;
                        padding: 5px 10px;
                        position: absolute;
                        text-align: center;
                        top: 35px;
                        z-index: 99;
                        word-break: normal;
                        &:before {
                            content: '';
                            display: block;
                            width: 0;
                            height: 0;
                            position: absolute;
                            border-width: 8px;
                            border-style: solid;
                            border-top-color: transparent;
                            border-left-color: transparent;
                            border-right-color: transparent;
                            border-bottom-color: #000;
                            left: 20px;
                            top: -16px; } } } } }
        .reply-box {
            margin-top: 1em;
            @include respond-to-smaller(small-screens) {
                padding-left: 0; }
            .comment-vote {
                min-height: 50px; } }
        .view-more {
            color: $accent-color;
            cursor: pointer;
            margin-left: 1em; }
        .comment-add-reply {
            margin-top: 10px;
            margin-left: 15%; } }
    h1.title {
        border-bottom: 1px solid $comment-grey;
        margin-bottom: 10px;
        line-height: 200%;
        font-size: 24px;
        padding-bottom: 10px; }
    .sort-box {
        text-align: right;
        span {
            font-size: 14px;
            text-transform: uppercase; }

        div > span:first-child:after {
            content: ': '; }
        a {
            margin-left: 10px;
            cursor: pointer;
            &.active {
                cursor: default;
                position: relative;
                color: $accent-color;
                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-bottom: 4px solid $accent-color;
                    top: 5px;
                    left: 0; } } }
        @include respond-to-smaller(medium-screens) {
            text-align: left;
            padding-bottom: 1em;
            span {
                font-size: 12px; } } }
    .loading-svg {
        background: url("../../../../images/loading-bars.svg");
        background-size: contain;
        height: 80px;
        margin: 20px auto;
        width: 80px; }
    .pagination {
        padding: 10px 2em;
        text-align: center;
        display: block;
        .comment-load-more {
            text-transform: uppercase;
            font-size: 14px;
            padding: 0 2em; }
        a {
            float: right;
            width: 100px; } }
    .text-area-box {
        text-align: center;
        .alert-moderation {
            max-width: 1000px;
            text-align: left;
            margin: 0 auto;
            margin-bottom: 1em;
            font-size: 0.8em; } }
    .not-login {
        width: 100%;
        border-radius: 5px;
        border: 1px solid #e3e3e3;
        text-align: center;
        position: relative;
        //transition: all 0.5s ease-in-out
        cursor: pointer;
        display: inline-block;
        margin: 0 auto 25px auto !important;
        &:hover {
            background: lighten($accent-color, 5%); }
        span {
            &:hover {
                color: #313233 !important; }
            color: #666  !important;
            text-transform: uppercase;
            letter-spacing: 1px; }
        a {
            color: $accent-color; } }
    .accent-color {
        color: $accent-color; } }


.ReactModalPortal {
    position: relative;
    z-index: 99999;

    .ReactModal__Content {
        position: absolute !important;
        transform: translateY(-50%);
        background-color: #181818;
        margin: 0 auto !important;
        top: 50% !important;
        right: 0 !important;
        left: 0 !important;
        bottom: auto !important;
        max-width: 90%;
        max-height: 98%; }
    .btn {
        margin: 0 auto; }
    .edit-modal, .reply-edit-modal {
        @include modalHeader;
        .delete-info {
            text-align: center; }
        .comment-edit-box {
            margin: 20px auto 0;
            width: 95%;
            .textile-container {
                ul {
                    padding-left: 0; } }
            .btn {
                margin-top: 20px;
                display: block;
                @include respond-to-smaller(small-screens) {
                    line-height: 1;
                    padding: 1em; } } } }
    .delete-modal {
        text-align: center;
        @include modalHeader;
        a.btn-primary {
            color: $accent-text !important; } }
    .login-modal {
        @include modalHeader; } }
