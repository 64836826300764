@import "../../../../../styles/base/_colors.sass";
.read-more {
    $self: &;
    &__text {
        overflow-wrap: break-word;
        overflow: hidden;
        white-space: pre-wrap;
        background: inherit;
        padding: 0 2px; }

    &--collapsed {
        position: relative;
        width: 100%;
        #{$self}__mask {
            position: absolute;
            width: 100%;
            background: linear-gradient(transparent, #000) !important;
            border-bottom: 1px solid #3b3c3d !important; }
        #{$self}__btn {
            cursor: pointer;
            margin: 5px 0;
            color: $accent-color !important;
            float: right;
            &:hover {
                color: $accent-hover-light !important; } } } }
