@import "../../../../../styles/base/colors";
.text-editor {
    margin: 1.5em 0;
    @media screen and (max-width: 420px) {
        margin: 1.5em 0 0; }
    &.disabled .ql-toolbar.ql-snow button {
        pointer-events: none; }
    &.error .text-editor__container {
        border: 1px solid $error; }

    &__container {
        position: relative; }

    .ql-toolbar.ql-snow {
        position: relative;
        text-align: left;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        background-color: $light-bg;
        border-bottom: none;
        font-family: $font-family;

        .ql-formats {
            background-color: $white;
            border: 1px solid $border-gray;
            border-radius: 4px;
            overflow: hidden;
            >button:not(:first-child) {
                border-left: 1px solid $border-gray; } }
        button {
            height: 26px;
            padding: 5px;
            border-radius: 0;

            &.source {
                width: auto; }

            &.ql-active, .selected {
                background-color: #f7f7f7; } }

        .ql-picker {
            height: 26px; } }

    .ql-container {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        border-color: $border-gray;
        font-family: $font-family;

        .ql-editor {
            min-height: 12em;
            font-size: medium;
            -webkit-user-select: text;
            -khtml-user-select: text;
            -moz-user-select: text;
            -ms-user-select: text;
            user-select: text;
            caret-color: white;
            li {
                padding-left: 0!important; } }

        .ql-blank {
            &:before {
                font-style: normal;
                font-size: 1em;
                position: absolute;
                pointer-events: none;
                left: 15px;
                transition: 0.2s ease all;
                color: lighten($formGrayText, 20%); } } }
    .ql-tooltip {
        white-space: normal;
        background-color: #262626;
        border-color: #3b3c3d !important;
        color: #f5f5f5;
        .protocolSelect {
            display: none; }
        &.ql-editing[data-mode="link"] {
            .protocolSelect {
                display: inline-block;
                caret-color: #f5f5f5; }
            select {
                height: 26px;
                margin-right: 0.1em;
                background-color: #131313;
                border: solid 1px #3b3c3d;
                color: #f5f5f5;
                outline: none;
                font-size: 13px; }
            input {
                background-color: #131313;
                border: solid 1px #3b3c3d;
                color: #f5f5f5;
                outline: none; }
            input.error {
                border-color: $red; } } }

    .tool-buttons {
        display: inline-block;
        position: relative; }

    .character-amount {
        position: absolute;
        right: 22px;
        top: 8px;
        font-size: .8em;
        line-height: 2.2em;
        z-index: 1;
        @media screen and ( max-width: 420px  ) {
            top: -25px; } }

    &--error {
        color: $error;
        line-height: 2em; } }
