@import "../../../../styles/base/helpers";
@import '../../../../styles/base/_colors';
@import "../../../../styles/mixins/breakpoints";
.auto-complete-panel {
    position: relative;
    .input-search {
        display: block;
        white-space: nowrap;
        vertical-align: middle;
        width: 100%;
        height: 38px;
        padding: 5px 10px;
        background-color: #171717;
        border: 1px solid $nav-color;
        box-shadow: none !important;
        transition: all .3s ease-in-out;

        span {
            position: absolute;
            right: 5px;
            width: 1.5em;
            height: 1.5em;
            margin: 0;
            cursor: pointer;
            text-align: center;
            color: #fff; }

        input {
            border: 0;
            border-radius: 0;
            outline: 0;
            background-color: transparent;
            margin: 0;
            height: 100%;
            padding-left: 0;
            vertical-align: top;
            color: #fff;
            font-size: 16px;
            @include respond-to(medium-screens) {
                font-size: 14px; }
            @include calc(width, "100% - 4em");

            &:focus {
                outline: 0; } }
        >i {
            position: absolute;
            height: 1em;
            width: 1em;
            line-height: 1em;
            font-size: 1.5em;
            right: calc(1em + 5px);
            &.search-loading {
                background: url("../../../../images/loading-spinner-small.svg") center no-repeat; }
            &.search-cancel {
                text-align: center;
                cursor: pointer;
                color: #fff;
                &::before {
                    content: 'x'; } } }

        &.disabled {
            cursor: not-allowed;
            background-color: darken($nav-color, 10%);
            input {
                cursor: not-allowed; } } }
    ul.autocomplete {
        background: $nav-color;
        position: absolute;
        border: 1px solid $nav-color;
        border-top: 0;
        padding: .2em 0;
        width: 100%;
        max-height: 60vh;
        overflow-y: auto;
        li {
            background: white;
            padding: .4em .5em .3em;
            font-size: .85em;
            cursor: pointer;
            text-overflow: ellipsis;
            display: block;
            overflow-x: hidden;
            white-space: nowrap;
            &:hover {
                background: $header-border; }
            &.selected {
                background: $accent-color;
                color: $accent-text; } }
        strong {
            font-weight: bold; } } }

.rc-pagination-options-quick-jumper input {
    background-color: #171717;
    color: #fff; }
