.tab {
    padding: 12px 1em;
    min-width: 11.2em;
    text-align: center;
    cursor: pointer;
    border: none;
    border-bottom: 3px solid transparent;
    @media screen and ( max-width: 420px  ) {
        text-align: left; }
    &:hover {
        background: $darker-text; } }

.tab__btn {
    background-color: transparent;
    font-family: $font-family;
    font-size: 24px; }

.tab--active {
    border-color: $accent-color;
    font-weight: bold; }
